import styled from 'styled-components'

export const FooterWrapper = styled.footer`
  display: flex;
  justify-content: center;
  margin: 48px calc(-50vw + 600px) 0;
  color: #fff;
  background: rgb(51, 51, 51);
`

export const CopyRight = styled.p`
  display: inline-block;
  font-size: 0.87rem;
`

export const Record = styled.p`
  display: inline-block;
  margin-left: 16px;
  font-size: 0.87rem;
`
