import { FC } from 'react'
import Link from 'next/link'
import { addFavorite, setHome } from 'src/shared/utils'
import {
  Logo,
  NavBar,
  NavBarItem,
  HeaderMeta,
  SetHomePage,
  HotLine,
} from './styled'

const Header: FC = () => {
  return (
    <header>
      <HeaderMeta>
        <Link href="/">
          <a>
            <Logo />
          </a>
        </Link>

        <div>
          <SetHomePage>
            <span onClick={() => setHome()}>设为首页</span> /{' '}
            <span onClick={() => addFavorite()}>加入收藏</span>
          </SetHomePage>
          <HotLine>咨询热线: 010 - 80365711</HotLine>
        </div>
      </HeaderMeta>
      <NavBar>
        <NavBarItem>
          <Link href="/">
            <a>网站首页</a>
          </Link>
        </NavBarItem>
        <NavBarItem>
          <Link href="/about">
            <a>画院简介</a>
          </Link>
        </NavBarItem>
        <NavBarItem>
          <Link href="/artist">
            <a>书画名家</a>
          </Link>
        </NavBarItem>
        <NavBarItem>
          <Link href="/post/news">
            <a>最新动态</a>
          </Link>
        </NavBarItem>
        <NavBarItem>
          <Link href="/work">
            <a>书画欣赏</a>
          </Link>
        </NavBarItem>
        <NavBarItem>
          <Link href="/post/comment">
            <a>名家点评</a>
          </Link>
        </NavBarItem>
        <NavBarItem>
          <Link href="/post/course">
            <a>艺术讲堂</a>
          </Link>
        </NavBarItem>
        <NavBarItem>
          <Link href="/contact">
            <a>联系我们</a>
          </Link>
        </NavBarItem>
      </NavBar>
    </header>
  )
}

export default Header
