import styled from 'styled-components'
import { ALI_OSS_PREFIX } from 'src/shared/constants'

export const NavBar = styled.nav`
  margin: 0 calc(-50vw + 600px);
  text-align: center;
  background: #d10000;
  height: 48px;
`

export const NavBarItem = styled.div`
  display: inline-block;
  width: 120px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
`

export const Logo = styled.div`
  margin: 24px 0;
  width: 450px;
  height: 100px;
  background: url(${ALI_OSS_PREFIX}/logo.jpg) no-repeat center center;
  background-size: cover;
`

export const HeaderMeta = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SetHomePage = styled.div`
  margin-bottom: 24px;
  font-size: 12px;
  text-align: right;
  cursor: pointer;
`

export const HotLine = styled.div`
  font-size: 16px;
  font-weight: 500;
`
