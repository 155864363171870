export interface IArtist {
  readonly _id: string
  readonly name: string
  readonly avatarUrl: string
  readonly introductionHTML: string
  readonly introductionRAW: string
}

export interface ICarousel {
  readonly _id: string
  readonly title: string
  readonly imageUrl: string
}

export interface IWork {
  readonly _id: string
  readonly name: string
  readonly introductionRAW: string
  readonly introductionHTML: string
  readonly imageUrl: string
}

export interface IPost {
  readonly _id: string
  readonly title: string
  readonly contentRAW: string
  readonly contentHTML: string
  readonly lastModified: string
  readonly category: Category
  readonly pv: number
  readonly createdAt: string
  readonly updatedAt: string
}

export enum Category {
  News,
  Comment,
  Course,
  About,
  Contact,
}

export type PostLite = Pick<IPost, '_id' | 'title' | 'createdAt'>

export interface ICurrPostWithNextAndPrev extends IPost {
  readonly prev: PostLite | null
  readonly next: PostLite | null
}

export interface IPostsFindByCategoryAndPage {
  readonly posts: IPost[]
  readonly total: number
  readonly page: number
  readonly pageSize: number
}
