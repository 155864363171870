import { FC } from 'react'

import Head from 'src/components/Head/Head'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import { Layouts, Main } from './styled'

interface Props {
  title?: string
}

const Layout: FC<Props> = ({ title, children }) => {
  return (
    <Layouts>
      <Head title={title} />
      <Header />
      <Main>{children}</Main>
      <Footer />
    </Layouts>
  )
}

export default Layout
