import { Category } from 'src/types'

export const SNACKBAR_MAX_NUM = 1

export const SNACKBAR_AUTO_HIDE_DURATION = 3000

export const ALI_OSS_PREFIX = 'https://assets-ptshy.oss-cn-beijing.aliyuncs.com'

export const ids = {
  [Category.Contact]: '604cfbff7174a9caf20742d7',
  [Category.About]: '6046fe1b7174a9caf20742cd',
}
