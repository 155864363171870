export const noop = () => {}

export const addFavorite = () => {
  // eslint-disable-next-line no-alert
  alert('加入收藏夹失败，请按 Ctrl + D 添加！')
}

export const setHome = () => {
  // eslint-disable-next-line no-alert
  alert(
    `抱歉，您所使用的浏览器无法完成此操作。\n\n您需要手动将【 ${window.location.href} 】设置为首页。`,
  )
}
