import styled from 'styled-components'

export const Layouts = styled.section`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

export const Main = styled.main`
  flex: 1;
  overflow-x: hidden;
`
