import { FC } from 'react'
import Head from 'next/head'

interface Props {
  title?: string
}

const MetaHead: FC<Props> = ({ title = '菩提书画院' }) => {
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, shrink-to-fit=no, user-scalable=0"
        />
      </Head>

      <Head>
        <meta name="theme-color" content="#ffffff" />
      </Head>

      <Head>
        <meta
          name="description"
          content="菩提书画院是集美术、书法、研究、教育、展览、学术交流、作品收藏于一体的非营利性社会服务的专业书画院。主要面向全国和世界弘扬我国传统书画艺术，进行国内外书画艺术交流，展示中华文化的独特魅力。在有关部门的监管和要求下，组织开展了多次大型书画展览、全国采风、公益事业活动。产生了较好的社会影响力，并受到了有关部门的肯定和支持.书画院的宗旨是继承传统、开拓创新、弘扬华夏文明，彰显书画光彩。书画院将为我国的书画工作者及爱好者提供广阔的发展空间及全方位的发展支持，培养扶植书画艺术界后起之秀，组织国内外文化艺术交流和名家书画作品展览活动，更好的为祖国文化事业做贡献。这里有您成长、发展的空间，是您交流、合作的平台，是您静心、养生的基地。"
        />
      </Head>
    </>
  )
}

export default MetaHead
