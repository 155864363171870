import { FC } from 'react'
import { FooterWrapper, CopyRight, Record } from './styled'

const Footer: FC = () => {
  return (
    <FooterWrapper>
      <CopyRight>版权所有 菩提书画院 北京市房山区长阳大宁村东329号</CopyRight>
      <Record>
        <a
          href="https://beian.miit.gov.cn/#/Integrated/index"
          target="_blank"
          rel="noopener noreferrer"
        >
          京ICP备: 12011491号-1
        </a>
      </Record>
    </FooterWrapper>
  )
}

export default Footer
