import axios, { AxiosResponse } from 'axios'
import {
  IArtist,
  ICarousel,
  IWork,
  IPostsFindByCategoryAndPage,
  ICurrPostWithNextAndPrev,
  IPost,
  Category,
} from 'src/types'

const request = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
})

export const fetchArtists = () =>
  request.get('/artists').then((res: AxiosResponse<IArtist[]>) => res.data)

export const fetchArtistById = (id: string) =>
  request.get(`/artists/${id}`).then((res: AxiosResponse<IArtist>) => res.data)

export const fetchWorks = () =>
  request.get('/works').then((res: AxiosResponse<IWork[]>) => res.data)

export const fetchWorkById = (id: string) =>
  request.get(`/works/${id}`).then((res: AxiosResponse<IWork>) => res.data)

export const fetchHorizontalCarousels = () =>
  request
    .get('/horizontalCarousel')
    .then((res: AxiosResponse<ICarousel[]>) => res.data)

export const fetchVerticalCarousels = () =>
  request
    .get('/verticalCarousel')
    .then((res: AxiosResponse<ICarousel[]>) => res.data)

export const fetchPosts = (category: Category, page = 1, pageSize = 10) =>
  request
    .get('/posts', {
      params: {
        category,
        page,
        pageSize,
      },
    })
    .then((res: AxiosResponse<IPostsFindByCategoryAndPage>) => res.data)

export const fetchPostById = (id: string) =>
  request
    .get(`/posts/${id}`)
    .then((res: AxiosResponse<ICurrPostWithNextAndPrev>) => res.data)

export const addPV = (id: string) =>
  request.put(`/posts/pv/${id}`).then((res: AxiosResponse<IPost>) => res.data)
